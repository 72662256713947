var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"shadow-question-card rounded-card max-w-md lg:max-w-lg mx-auto lg:mx-0 px-4 lg:px-7 pt-5 pb-4 bg-white text-left"},[(!_vm.activeSession.hidePollLabels)?_c('div',{staticClass:"flex flex-row justify-between"},[_c('p',{staticClass:"text-xs pb-2",style:({
        color: _vm.theme.textColor,
      })},[_vm._v(" Poll "+_vm._s(_vm.index + 1)+" ")]),_c('p',{staticClass:"text-xs pb-2",style:({
        color: _vm.theme.textColor,
      })},[_vm._v(" "+_vm._s(_vm.pollTypes[_vm.poll.type])+" ")])]):_vm._e(),_c('div',{staticClass:"flex justify-between"},[_c('h3',{staticClass:"text-lg",style:({ color: _vm.theme.headlineColor })},[_vm._v(" "+_vm._s(_vm.poll.title)+" ")])]),(_vm.poll.choices)?_c('div',{staticClass:"flex flex-col space-y-4 mt-3"},[(!_vm.poll.answered && !_vm.poll.isPaused)?[_vm._l((_vm.poll.choices),function(choice,j){return _c('button',{key:j,staticClass:"w-full py-2 text-md rounded-full border active:opactiy-50",style:({
          color: choice.selected ? _vm.theme.buttonTextColor : _vm.theme.buttonColor,
          'background-color': !choice.selected
            ? _vm.theme.buttonTextColor
            : _vm.theme.buttonColor,
          'border-color': choice.selected
            ? _vm.theme.buttonTextColor
            : _vm.theme.buttonColor,
        }),on:{"click":function($event){$event.preventDefault();return _vm.selectChoice(
            ((choice.label) + "-" + (_vm.poll.id)),
            _vm.poll.id,
            _vm.poll.type,
            _vm.poll.choices
          )}}},[_vm._v(" "+_vm._s(choice.label)+" ")])}),(_vm.poll.textFieldPlaceholder != '')?_c('div',{staticClass:"flex flex-col space-y-4 mt-2"},[(!_vm.poll.answered)?[_c('input',{staticClass:"block py-3 px-5 w-full rounded-card border-none shadow-md placeholder-light-gray focus:ring-dark-gray focus:border-dark-gray",style:({ color: _vm.theme.headlineColor }),attrs:{"name":"pollAnswer","type":"text","placeholder":_vm.poll.textFieldPlaceholder},on:{"input":function (e) { return _vm.saveTextFieldInput(e, _vm.poll.id, _vm.poll.type); },"focus":function (e) { return _vm.focusTextField(_vm.poll.type); }}})]:_vm._e()],2):_vm._e(),(_vm.poll.type === 3)?_c('div',{staticClass:"my-3 w-full flex flex-col justify-center"},[_c('input',{staticClass:"h-5 w-full rounded-full outline-none transition-all duration-500",attrs:{"id":_vm.poll.id,"type":"range","min":_vm.poll.answerOptions.minValue,"max":_vm.poll.answerOptions.maxValue,"step":_vm.poll.answerOptions.stepValue,"defaultValue":_vm.poll.defaultValue,"autoComplete":"off"},domProps:{"value":_vm.pollValues[_vm.poll.id] ? _vm.pollValues[_vm.poll.id] : 0},on:{"input":function (e) { return _vm.updatePollValue(_vm.poll.id, e); }}}),_c('label',{staticClass:"text-center",attrs:{"for":_vm.poll.id}},[_vm._v(" "+_vm._s(_vm.pollValues[_vm.poll.id] ? _vm.pollValues[_vm.poll.id] : 0)+" % ")])]):_vm._e(),_c('button',{staticClass:"rounded-full py-2",style:({
          color: _vm.theme.buttonTextColor,
          'background-color': _vm.theme.buttonColor,
        }),on:{"click":function($event){return _vm.submitAnswer(
            _vm.poll.choices,
            _vm.poll.id,
            _vm.poll.textFieldPlaceholder && _vm.poll.textFieldPlaceholder != ''
          )}}},[_vm._v(" "+_vm._s(_vm.$t('views.btnSend'))+" ")])]:_vm._e(),(_vm.poll.isPaused && !_vm.poll.answered)?[_c('p',{staticClass:"text-sm italic",style:({ color: _vm.theme.textColor })},[_vm._v(" "+_vm._s(_vm.$t('views.viewer.sessionViews.pollsPaused'))+" ")])]:_vm._e(),(_vm.poll.choices && _vm.poll.answered)?_c('div',{staticClass:"relative lg:hidden flex flex-col justify-center"},[(_vm.poll.showPollStatistics)?_c('PollModal'):_vm._e(),_c('div',{staticClass:"w-full",style:(_vm.poll.showPollStatistics ? 'filter: blur(6px)' : 'filter: blur(0px)')},[_c('PollResultsWithTextField',{attrs:{"answers":_vm.poll.answers,"choices":_vm.poll.choicesPlain,"theme":_vm.theme,"label":"","voteResultType":_vm.activeSession.voteResultType}})],1),(!_vm.poll.showPollStatistics && _vm.poll.isViewerPieChartVisible)?_c('PollPieChart',{key:_vm.poll.answers,attrs:{"poll":_vm.poll}}):_vm._e()],1):_vm._e(),(_vm.poll.choices && _vm.poll.answered)?_c('div',{staticClass:"relative hidden lg:flex justify-between"},[(_vm.poll.showPollStatistics)?_c('PollModal'):_vm._e(),_c('div',{staticClass:"w-full",style:(_vm.poll.showPollStatistics ? 'filter: blur(6px)' : 'filter: blur(0px)')},[_c('PollResultsWithTextField',{attrs:{"answers":_vm.poll.answers,"choices":_vm.poll.choicesPlain,"theme":_vm.theme,"label":"","voteResultType":_vm.activeSession.voteResultType}})],1),(!_vm.poll.showPollStatistics && _vm.poll.isViewerPieChartVisible)?_c('PollPieChart',{key:_vm.poll.answers,attrs:{"poll":_vm.poll}}):_vm._e()],1):_vm._e(),(
        (_vm.poll.textFieldPlaceholder || _vm.poll.type === 3) &&
        _vm.poll.answered &&
        _vm.poll.userAnswer &&
        _vm.poll.showPollStatistics
      )?[_c('p',{staticClass:"text-md text-dark-gray"},[_vm._v(" "+_vm._s(_vm.$t('views.viewer.sessionViews.pollsInput'))+" "+_vm._s(_vm.poll.userAnswer.includes('von') ? _vm.poll.userAnswer.split('von')[0] : _vm.poll.userAnswer)+" ")])]:_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }