









































































































































































































import Vue from 'vue';
import PollModal from '@/components/Viewer/PollModal.vue';
import PollResultsWithTextField from '@/components/Presenter/PollResultsWithTextField.vue';
import PollPieChart from '@/components/PollPieChart.vue';

export default Vue.extend({
  components: {
    PollResultsWithTextField,
    PollModal,
    PollPieChart,
  },

  props: {
    poll: {
      type: Object,
      required: true,
    },
    activeSession: { type: Object, required: true },
    theme: { type: Object, required: true },
    pollTypes: { type: Array, required: true },
    selectChoice: { type: Function, required: true },
    saveTextFieldInput: { type: Function, required: true },
    focusTextField: { type: Function, required: true },
    submitAnswer: { type: Function, required: true },
    updatePollValue: { type: Function, required: true },
    pollValues: { type: Object, required: true },
    index: { type: Number, required: true },
  },
});
