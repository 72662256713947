












import Vue from 'vue';
import PieChart from '@/components/Presenter/SessionReport/PieChart.vue';
export default Vue.extend({
  components: {
    PieChart,
  },
  data() {
    return {
      pollTypes: ['Singlechoice', 'Multiplechoice '],

      options: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          labels: {
            fontColor: '#4B5362',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },

      colorSelection: [
        'rgba(103,237,184,1)',
        'rgba(82,219,194,1)',
        'rgba(62,200,210,1)',
        'rgba(41,182,227,1)',
        'rgba(21, 164,240,1)',
        'rgba(2,133,253,1)',
        'rgba(6,96,243,1)',
        'rgba(11, 48, 233,1)',
        'rgba(16, 0, 222,1)',
      ],
    };
  },
  props: {
    poll: {
      type: Object,
      required: true,
    },
  },

  computed: {
    computedData(): number[] {
      const data: number[] = [];
      this.poll.choices.map((choice: string, i: number) => {
        data.push(this.calcAbsForAnswers(this.poll.answers, i)['absCount']);
      });

      return data;
    },
    computedChartData(): any {
      const label: string[] = [];

      if (this.poll.choices[0].label) {
        this.poll.choices.map(
          (choice: { id: number; label: string; selected: boolean }) =>
            label.push(choice.label)
        );
      } else {
        this.poll.choices.map((choice: string) => label.push(choice));
      }

      return {
        labels: label,
        datasets: [
          {
            borderWidth: 1,
            borderColor: this.pickColors(this.poll.choices.length),
            backgroundColor: this.pickColors(this.poll.choices.length),
            data: this.computedData,
          },
        ],
      };
    },
  },
  methods: {
    calcAbsForAnswers(
      answers: any[],
      choice: number
    ): { absCount: number; sum: number } {
      let absCount = 0;
      let sum = 0;
      if (answers) {
        Object.values(answers).map((answer: any) => {
          if (answer instanceof Array) {
            sum += answer.length;
            if (answer.includes(choice)) {
              absCount += 1;
            }
          } else {
            sum += 1;
            if (answer === choice) {
              absCount += 1;
            }
          }
        });
      }
      return { absCount, sum };
    },
    pickColors(n: number): string[] {
      const colors: string[] = [];
      for (let i = n; i--; ) {
        colors.push(this.colorSelection[i % 9]);
      }
      return colors;
    },
  },
});
