










import Vue from 'vue';
import { mapGetters } from 'vuex';
import ThemeModel from '@/dtos/theme';

export default Vue.extend({
  computed: {
    ...mapGetters({
      sessionTheme: 'viewer/getSessionTheme',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
  },
});
