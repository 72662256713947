





























































import Vue from 'vue';
import { formatDateTime } from '@/utils/tools';
import { mapGetters } from 'vuex';
import SessionWrapper from '@/components/Viewer/SessionWrapper.vue';
import ThemeModel from '@/dtos/theme';
import PollList from '@/components/Viewer/PollList.vue';

export default Vue.extend({
  components: {
    SessionWrapper,
    PollList,
  },
  async mounted() {
    if (
      this.activeSession.userGroup &&
      this.activeSession.userGroup === 'prematch'
    ) {
      await this.$store.dispatch('viewer/fetchPollsUserIPs');
    }
  },
  data() {
    return {
      now: new Date(),
      selectedChoices: [] as any[],
      textFieldInputs: {} as { [id: string]: string },
      pollTypes: ['Singlechoice', 'Multiplechoice', 'Freitextfeld', 'Slider'],
      pollValues: {} as { [id: string]: number },
      selectedStageId: '',
    };
  },
  computed: {
    ...mapGetters({
      polls: 'viewer/getSessionPolls',
      userId: 'auth/getUserId',
      activeSession: 'viewer/getActiveSession',
      sessionTheme: 'viewer/getSessionTheme',
      answeredPollIds: 'viewer/getAnsweredPollIds',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },

    computedPolls(): any[] {
      if (this.polls && this.userId) {
        const customPolls = [];
        for (const poll of this.polls as any) {
          // User entered text field answer
          let userAnswer = false;
          if (this.userId in poll.answers) {
            userAnswer = poll.answers[this.userId].filter(
              (answer: any) => typeof answer === 'string'
            )[0];
          }

          const customPoll = {
            id: poll.id,
            title: poll.title,
            choices: [],
            choicesPlain: poll.choices,
            answered:
              this.userId in poll.answers ||
              this.answeredPollIds.includes(poll.id),
            userAnswer,
            answers: poll.answers,
            textFieldPlaceholder: poll.textFieldPlaceholder,
            type: poll.type,
            isPaused: poll.isPaused,
            answerOptions: poll.answerOptions ? poll.answerOptions : {},
            isViewerPieChartVisible: poll.isViewerPieChartVisible,
            showPollStatistics: poll.showPollStatistics,
            stage: poll.stage,
          } as any;
          let i = 0;
          for (const choice of poll.choices as any) {
            customPoll.choices.push({
              id: i,
              label: choice,
              selected: this.selectedChoices.includes(`${choice}-${poll.id}`),
            });
            i++;
          }
          customPolls.push(customPoll);
        }

        if (this.activeSession.stages) {
          return customPolls.filter(
            (poll: any) => poll.stage === this.computedStageValue
          );
        }
        return customPolls;
      }
      return [];
    },

    filteredStages(): any[] {
      if (this.activeSession.stages) {
        return Object.values(this.activeSession.stages)
          .filter((stage: any) => !!stage.visible)
          .sort((a: any, b: any) => a.order - b.order);
      }
      return [];
    },

    computedStageValue(): string {
      if (this.selectedStageId) {
        return this.selectedStageId;
      }
      return this.filteredStages[0].id;
    },
  },
  methods: {
    formatDateTime(input: any): string {
      return formatDateTime(input);
    },
    submitAnswer(
      choices: any[],
      pollId: string,
      isTextFieldEnabled: boolean
    ): void {
      const res = [];
      for (const choice of choices) {
        if (choice.selected) {
          res.push(choice.id);
        }
      }
      if (
        isTextFieldEnabled &&
        this.textFieldInputs[pollId] &&
        this.textFieldInputs[pollId] !== ''
      ) {
        res.push(this.textFieldInputs[pollId]);
      }
      if (this.pollValues[pollId]) {
        res.push(`${this.pollValues[pollId]} %`);
      }
      this.$store.dispatch('viewer/submitPollAnswer', { pollId, choices: res });
    },
    selectChoice(
      choice: string,
      pollId: string,
      pollType: number,
      choices: any[]
    ): void {
      // If Singlechoice, clean list since only one answer can be submitted
      if (pollType === 0) {
        for (const tempChoice of choices as any) {
          const choiceId = `${tempChoice.label}-${pollId}`;
          if (this.selectedChoices.includes(choiceId)) {
            this.selectedChoices = this.selectedChoices.filter(
              (item) => item !== choiceId
            );
          }
        }
        this.textFieldInputs[pollId] = '';
      }

      if (this.selectedChoices.includes(choice)) {
        this.selectedChoices = this.selectedChoices.filter(
          (item) => item !== choice
        );
      } else {
        this.selectedChoices.push(choice);
      }
    },
    saveTextFieldInput(e: any, pollId: string, pollType: number): void {
      // Reset other poll inputs if single choice
      const value = e.target.value;
      if (value) {
        if (pollType === 0) {
          this.selectedChoices = [];
        }
        this.textFieldInputs[pollId] = value;
      }
    },
    focusTextField(pollType: number): void {
      if (pollType === 0) {
        this.selectedChoices = [];
      }
    },
    updatePollValue(id: string, e: any) {
      this.pollValues = { ...this.pollValues, [id]: e.target.value };
    },
    selectStage(event: any): void {
      this.selectedStageId = event.target.value as string;
    },
  },
});
